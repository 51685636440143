<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 검사기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-1M"
            defaultEnd="1M"
            label="검사기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 검사기관 -->
          <c-text
            label="검사업체"
            name="inspectionAgency"
            v-model="searchParam.inspectionAgency"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="검사결과 목록"
      tableId="hazardEquipmentHis"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :usePaging="false"
      :filtering="false"
      :checkClickFlag="false"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
        <q-btn-group outline>
          <!-- 등록 -->
          <c-btn label="LBLREG" v-if="editable" icon="add" @btnClicked="linkClick" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "hazard-equipment-result-register",
  data() {
    return {
      grid: {
        merge: [

        ],
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:80px', 
            align: "center",
            sortable: true,
          },
          {
            name: "inspectionDate",
            field: "inspectionDate",
            // 검사일
            label: "검사일",
            style: 'width:120px',
            align: "center",
            sortable: false,
            type: "link",
          },
          {
            name: "inspectionAgency",
            field: "inspectionAgency",
            label: "검사업체",
            style: 'width:120px',
            align: "left",
            sortable: false,
          },
          {
            name: "inspectionResultDetail",
            field: "inspectionResultDetail",
            label: "검사결과 상세",
            style: 'width:350px',
            align: "left",
            sortable: false,
          },
          {
            name: 'resultFile',
            field: 'resultFile',
            label: '검사결과',
            align: 'center',
            sortable: true,
            type: 'file',
            style: 'width: 200px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        inspectionAgency: '',
        period: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.hhm.hazard.inspResult.list.url;
      // list setting
      this.getList();
    },
    getList() {
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "검사결과 상세";
      this.popupOptions.param = {
        hhmHazardousMachineryInspectionRsltId: row ? row.hhmHazardousMachineryInspectionRsltId : '',
      };
      this.popupOptions.target = () =>
        import(`${"./hazardEquipmentResultRegisterDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '70%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>